application.register("comp_references_single_missiva_web", class extends Stimulus.Controller {
    connect() {
        const self = this;

        this.cardsStack()
    }

    cardsStack() {
        const self = this;
        this._cards = self.targets.findAll('card')

        if(this._cards.length < 2) {
            return
        }


        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            '(min-width: 60em)': () => {
                const timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: self.targets.find('scene'),
                        start: 'top-=200 top',
                        end: `+${100 * self._cards.length}%`,
                        scrub: true,
                        pin: true,
                        markers: false
                    }
                })

                self._cards.forEach((card) => {
                    ScrollTrigger.saveStyles(card)
                })

                timeline.fromTo(self._cards[0],
                    {
                        translateY: 0,
                        scale: 1,
                    },
                    {
                        translateY: '-22%',
                        scale: 0.7
                    }
                )

                timeline.fromTo(self._cards[1],
                    {
                        translateY: '100vh',
                        scale: 1,
                    },
                    {
                        translateY: '-15%',
                        scale: 0.8
                    },
                    '>-60%'
                )

                timeline.fromTo(self._cards[2],
                    {
                        translateY: '100vh'
                    },
                    {
                        translateY: '-7.5%',
                        scale: 0.9
                    },
                    '>-40%'
                )

                timeline.fromTo(self._cards[3],
                    {
                        translateY: '100vh'
                    },
                    {
                        translateY: '0%',
                    },
                    '>-20%'
                )
            }
        })
    }

    playVideo({currentTarget}) {
        arguments[0].preventDefault()

        const video = currentTarget.closest('[data-ref="video"]').querySelector('video');

        if(video !== null) {
            video.setAttribute('controls','')
            video.play()
            currentTarget.style.opacity = 0
            currentTarget.style.pointerenter = 'none'
        }
    }

    resetVideo({currentTarget}) {
        const button = currentTarget.closest('[data-ref="video"]').querySelector('[data-ref="playButton"]');
        currentTarget.removeAttribute('controls')
        currentTarget.currenttime = 0

        if(button !== null) {
            button.style.opacity = null
            button.style.pointerenter = null
        }
    }
})
